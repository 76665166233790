<template>
  <div class="app-main-content review-list">
    <card :tabs="statusList" :allow="false" v-model="listQuery.status" body-class="page-card-body" custom-tools-form
          @change="search">
      <template #tools>
        <div>
          <a-button type="primary" @click="$router.push('form')">
            <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
            汇报
          </a-button>
        </div>
        <div class="right">
          <div class="item">
            <a-input class="review-input" style="width: 200px" placeholder="搜索姓名，提交内容"
                     v-model="listQuery.keywords">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD" class="review-range"
                            v-model="listQuery.dateRange">
              <template #default>
                <div class="review-range-slot">
                  <div class="review-range-input star">
                    <img class="icon" :src="$toUrl('review-date.png')" alt="">
                    {{ listQuery.dateRange[0] ?? '开始时间' }}
                  </div>
                  <div class="text">至</div>
                  <div class="review-range-input end">
                    <img class="icon" :src="$toUrl('review-date.png')" alt="">
                    {{ listQuery.dateRange[1] ?? '结束时间' }}
                  </div>
                </div>
              </template>
            </a-range-picker>
          </div>
          <div class="item">
            <a-button @click="exportReport" type="primary">
              <a-icon type="download" />
              导出
            </a-button>
          </div>
        </div>
      </template>
      <div class="body">
        <div class="result-num">共{{ page.total }}条符合条件的结果</div>
        <a-table :pagination="pagination" rowKey="oid" :data-source="list" style="width: 1529px" bordered
                 :rowSelection="{selectedRowKeys,onChange: changeSelected}">
          <a-table-column title="姓名" data-index="creator_name"></a-table-column>
          <a-table-column title="提交内容" data-index="rpt_name"></a-table-column>
          <a-table-column title="提交时间" data-index="submit_time"></a-table-column>
          <a-table-column title="状态">
            <template #default="_, row">
              {{
                { 0: '待提交', 1: '待审核', 2: '已审核' }[row.status]
              }}
            </template>
          </a-table-column>
          <a-table-column title="审批人">
            <template #default="_, row">
              <template>{{ row.auditor_title ? row.auditor_name + '-' + row.auditor_title : row.auditor_name }}</template>
            </template>
          </a-table-column>
          <a-table-column title="审批时间" data-index="audit_time"></a-table-column>
          <a-table-column title="操作" width="150px">
            <template #default="_, row">
              <a-space>
                <template
                    v-if="getRowPermission(row, currentUserOid).includes('修改') || getRowPermission(row, currentUserOid).includes('审核')">
                  <span v-if="getRowPermission(row, currentUserOid).includes('审核')" class="blue"
                        @click="jumpDetail(row)">审批</span>
                  <span v-else-if="getRowPermission(row, currentUserOid).includes('修改')" class="blue"
                        @click="jumpDetail(row)">修改</span>
                </template>
                <span v-if="getRowPermission(row, currentUserOid).includes('查看')" class="blue"
                      @click="jumpDetail(row)">查看</span>
                <span v-if="getRowPermission(row, currentUserOid).includes('提交')" class="blue"
                      @click="updateStatus(row, '1')">提交</span>
                <span v-if="getRowPermission(row, currentUserOid).includes('删除')" @click="deleteReport(row)"
                      class="blue">删除</span>
              </a-space>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </card>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin";
import { getRowPermission } from "@/view/workspace/review/review";
import appsettings from "@/utils/appsettings";
import { fetch } from "@/utils/request";

export default {
  mixins: [listMixin],
  data() {
    return {
      listMixinOptions: {
        url: "report/retrieve"
      },
      statusList: [
        { cat_code: '2', cat_name: '已审核' },
        { cat_code: '1', cat_name: '待审核' },
        { cat_code: '0', cat_name: '待提交' },
      ],
      listQuery: {
        status: '0',
        dateRange: [],
        keywords: ""
      },
      page: {
        total: 10
      },
      selectedRowKeys: [],
      currentUserOid: ""
    }
  },
  created() {
    const company_info = JSON.parse(localStorage.getItem("company_info"))
    this.currentUserOid = company_info.account_oid
  },
  methods: {
    updateStatus(row, status) {
      this.$confirm({
        title: `确定要提交汇报:${row.rpt_name}吗?`,
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk: () => {
          fetch("post", "/report/update", {
            ...row,
            status
          }).then(res => {
            if (res.status === 0) {
              this.getList()
              this.$message.success("提交成功")
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    deleteReport(row) {
      this.$confirm({
        title: `确定要删除汇报 ${row.rpt_name}吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          fetch('post', '/report/delete', {
            oid: row.oid,
          }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功！')
              this.getList()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    },
    exportReport() {
      let token = localStorage.getItem("token")
      let url = `${appsettings.apiroot}report/export?token=${token}`
      if (this.selectedRowKeys.length) {
        url += "&oids=" + this.selectedRowKeys.join(",")
      } else {
        url += "&oids=" + this.list.map(item => item.oid)
      }
      window.open(url)
    },
    getRowPermission,
    listFilter(list) {
      return list.map(item => {
        let audit_time = item.audit_time
        if (audit_time.indexOf("1900") === 0) {
          audit_time = ""
        }
        return {
          ...item,
          audit_time
        }
      })
    },
    jumpDetail(row) {
      this.$router.push({
        path: "form",
        query: {
          oid: row.oid
        }
      })
    },
    getListQuery() {
      const query = {
        ...this.query,
        status: this.listQuery.status,
        keywords: this.listQuery.keywords
      }
      if (Array.isArray(this.listQuery.dateRange)) {
        if (this.listQuery.dateRange[0]) {
          query.start_time = this.listQuery.dateRange[0]
        }
        if (this.listQuery.dateRange[1]) {
          query.end_time = this.listQuery.dateRange[0]
        }
      }
      return query
    },
    changeSelected(list) {
      this.selectedRowKeys = list
    }
  }
}
</script>

<style lang="less" scoped>
.review-input {
  height: 30px;
  width: 212px;

  /deep/ .ant-input {
    height: 100%;
    font-size: 14px;
    border: 1px solid #e8e8e8;

    &:focus {
      border-color: var(--primary-color);
    }

    &::placeholder {
      font-weight: 500;
      color: #909090;
    }
  }
}

.review-list {
  .blue {
    color: #0059FF;
    cursor: pointer;
  }

  /deep/ .page-card-body {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .result-num {
      font-size: 16px;
      color: #535353;
      line-height: 1;
      margin-bottom: 20px;
      margin-top: 2px;
    }
  }
}
</style>
