export const getRowPermission = (row, account_oid) => {
  const permissions = {}
  // 待提交
  if (row.status === "0" && row.creator_oid === account_oid) {
    permissions["修改"] = true
    permissions["提交"] = true
    permissions["删除"] = true
  }
  // 待审核
  if (row.status === "1" && row.creator_oid === account_oid) {
    permissions["删除"] = true
    permissions["修改"] = true
  }
  if (row.status === "1" && row.auditor_oid === account_oid) {
    permissions["审核"] = true
    permissions["查看领导总结"] = true
    permissions["删除"] = true
  }
  if (row.status === "2") {
    permissions["查看"] = true
  }
  return Object.keys(permissions)
}