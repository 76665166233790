import {fetch} from "@/utils/request.js";

export default {
  data() {
    return {
      // 是否渲染表格DOM，用于设置了默认展开全部时 v-if="renderTable" 请求数据时通过此属性控制渲染做到新加载DOM的效果
      renderTable: true,
      // 请求到的数据列表
      list: [],
      // 发送请求时携带的参数
      listQuery: {},
      // 分页参数
      page: {
        page_size: 50,
        page_index: 1,
        total: 0,
      },
      // 页面中的配置默认值
      listMixinOptions: {
        // 接口是否启用分页
        usePage: true,
        // 是否在组件加载后自动请求
        autoRun: true,
        // mock的url 是否启用mock在main.js中修改window.mock
        mockUrl: "",
        // 接口地址
        url: ""
      }
    }
  },
  created() {
    if (this.listMixinOptions.autoRun) {
      this.getList()
    }
  },
  computed: {
    // 分页信息 a-table组件使用:pagination="pagination"接管表格的分页配置
    pagination() {
      return {
        current: this.page.page_index,
        pageSize: this.page.page_size,
        total: this.page.total,
        pageSizeOptions: ['10', '15', '30', '50'],
        showSizeChanger: true,
        hideOnSinglePage: false
      }
    }
  },
  methods: {
    // 在这里处理排序 一般是根据sort参数给this.listQuery赋值
    setSortQuery(sort) {
      return sort
    },
    // 请求到数据后的处理操作 返回值将被设置为this.list
    listFilter(list) {
      return list
    },
    paginationChange(pagination, filter, sort) {
      this.page.page_index = pagination.current
      this.page.page_size = pagination.pageSize
      this.setSortQuery(sort)
      this.getList()
    },
    // 由组件<a-table>调用 @change="tableChange"
    tableChange(pagination) {
      this.paginationChange(pagination)
    },
    // 每次请求数据时会调用，参数query为this.listQuery 返回值会作为发送请求时的参数
    getListQuery(query) {
      return query
    },
    // 搜索 将页面设置为1再执行查询操作
    search() {
      this.page.page_index = 1
      this.getList()
    },
    // 查询后根据res找到数据的方法 默认如果分页取res.data.datas 不分页取res.data
    findListForResponse(res) {
      if (this.listMixinOptions.usePage) {
        return res.data.datas
      } else {
        return res.data
      }
    },
    // 钩子函数 请求接口并给this.list赋值成功时被调用
    beforeGetList() {},
    // 请求接口方法 页面中不需要改动和引入 只在需要时调用即可
    getList() {
      let query = {}
      if (this.listMixinOptions.usePage) {
        Object.assign(query, this.page)
      }
      Object.assign(query, this.getListQuery(JSON.parse(JSON.stringify(this.listQuery))))
      let url = window.mock && this.listMixinOptions.mockUrl ? this.listMixinOptions.mockUrl : this.listMixinOptions.url
      fetch("post", url, query).then(async res => {
        console.log(res)
        if (res.status === 0 || window.mock === true) {
          let dataTemp = this.listFilter(this.findListForResponse(res))
          this.renderTable = false
          if (dataTemp instanceof Promise) {
            this.list = await dataTemp
            // const res = await dataTemp
            // this.getDetailOid(res[0].oid)
            // setTimeout(() => {
            //   this.list = res
            // },500)
          } else {
            this.list = dataTemp
          }
          this.renderTable =true
          if (this.listMixinOptions.usePage) {
            this.page = res.data.page
          }
          this.beforeGetList()
        }
      })
    }
  },
}
